import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-bellevue-washington.png'
import image0 from "../../images/cities/scale-model-of-cow-and-coyote-statue-in-bellevue-washington.png"
import image1 from "../../images/cities/scale-model-of-nw-adventure-tours-inc.-in-bellevue-washington.png"
import image2 from "../../images/cities/scale-model-of-conundroom-real-escape-rooms-in-bellevue-washington.png"
import image3 from "../../images/cities/scale-model-of-slater-park-in-bellevue-washington.png"
import image4 from "../../images/cities/scale-model-of-viretta-park-in-bellevue-washington.png"
import image5 from "../../images/cities/scale-model-of-clyde-beach-park-in-bellevue-washington.png"
import image6 from "../../images/cities/scale-model-of-luther-burbank-park-in-bellevue-washington.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Bellevue'
            state='Washington'
            image={image}
            lat='47.6101497'
            lon='-122.2015159'
            attractions={ [{"name": "Cow And Coyote Statue", "vicinity": "122 Central Way, Kirkland", "types": ["point_of_interest", "establishment"], "lat": 47.6767416, "lng": -122.20721950000001}, {"name": "NW Adventure Tours Inc.", "vicinity": "14670 NE 8th St #115, Bellevue", "types": ["point_of_interest", "establishment"], "lat": 47.6176943, "lng": -122.1449518}, {"name": "Conundroom Real Escape Rooms", "vicinity": "8250 165th Ave NE, Redmond", "types": ["point_of_interest", "establishment"], "lat": 47.6763434, "lng": -122.12009510000001}, {"name": "Slater Park", "vicinity": "2835 60th Ave SE, Mercer Island", "types": ["park", "point_of_interest", "establishment"], "lat": 47.5841803, "lng": -122.25296020000002}, {"name": "Viretta Park", "vicinity": "151 Lake Washington Blvd E, Seattle", "types": ["park", "point_of_interest", "establishment"], "lat": 47.619238, "lng": -122.28216090000001}, {"name": "Clyde Beach Park", "vicinity": "2 92nd Ave NE, Bellevue", "types": ["park", "point_of_interest", "establishment"], "lat": 47.6145607, "lng": -122.2172999}, {"name": "Luther Burbank Park", "vicinity": "2040 84th Ave SE, Mercer Island", "types": ["park", "point_of_interest", "establishment"], "lat": 47.5911806, "lng": -122.22566849999998}] }
            attractionImages={ {"Cow And Coyote Statue":image0,"NW Adventure Tours Inc.":image1,"Conundroom Real Escape Rooms":image2,"Slater Park":image3,"Viretta Park":image4,"Clyde Beach Park":image5,"Luther Burbank Park":image6,} }
       />);
  }
}